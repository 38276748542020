import axios from "axios";

export const getSections = () => {
  const form = new FormData();
  form.append("action", "site_content");
  return axios({
    url: true
      ? `https://cobertizo-dev-cms.alucinaprojects.com/api-services/?v=${Math.random()}`
      : `https://cms.cobertizo.com.mx/api-services/?v=${Math.random()}`,
    method: "POST",
    data: form,
  })
    .then((res) => res.data)
    .then((res) => {
      let home = res.home || {};
      let images = home.images || [];
      let slides = [];
      for (let item of images) {
        let { image = "", video = "" } = item;
        if (video.trim().length) {
          slides.push({ url: video, type: "video", ...item });
        } else if (image.trim().length) {
          slides.push({ url: image, type: "image", ...item });
        }
      }
      home["images"] = slides;
      res["home"] = home;

      let programas = res["programas"];
      let programasKeys = Object.keys(programas);

      for (let key of programasKeys) {
        programas[key] = { ...programas[key], id: key };
      }

      res["programas"] = programas;
      return res;
    });
};
